/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "/node_modules/swiper/swiper-bundle";
@import 'tippy.js/dist/tippy.css';

$breakpoints : (
    "xs": 0,
    "sm":576px,
    "md": 767px,
    "lg":992px,
    "xl": 1200px
    
);
@mixin xs{
    @media (min-width: map-get($breakpoints, "xs")){
        @content;
    }
}
@mixin sm{
    @media (min-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin md{
    @media (min-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin lg{
    @media (min-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin xl{
    @media (min-width: map-get($breakpoints, "xl")){
        @content;
    }
}

@mixin breakpoint($bp: 0) {
    @media (max-width: $bp){
        @content;
    }
    
}
@mixin minbreak($bp: 0) {
    @media (min-width: $bp){
        @content;
    }
    
}
a{
    cursor: pointer;
}
html{
    --ion-font-family: 'Lusitana', serif;
}

.container{
    max-width: 100%;
    margin: 0 auto;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    @include md{
        max-width: 85%;
        padding-left: 0;
        padding-right: 0;
    }
    @include lg{
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
    
}
.swiper-button-prev, .swiper-button-next{
    top: 26%;
  }
  .tippy-box{
    background-color: #211d4b;
  }

//   .my-class .alert-wrapper {
//     // max-width: 94% !important;
//     // color: red;
//     width: 320px;
// }


// :root {
//     .alert-radio-label {
//         left: 0 !important;
//         // width: 100%;
//         font-size: x-small;
//     }
// }

.popover-wide .alert-radio-label {
    font-size:x-small; 
    white-space: normal !important;
  }